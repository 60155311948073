import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8ec00bf0 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _37536ffb = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _a9fda636 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _7442ce84 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _2e8123b3 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _6bac4df9 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _75bd32a3 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _747e3a54 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _24203fa8 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _e9f8bf58 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _09a4b6f4 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _48980bd0 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _3c6e41f8 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _2568d1eb = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _d7ed6fa8 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _52d9c7de = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _bbf6aa3c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _8ec00bf0,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _37536ffb,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _a9fda636,
    name: "callback"
  }, {
    path: "/downloads",
    component: _7442ce84,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _2e8123b3,
    name: "guests-details"
  }, {
    path: "/login",
    component: _6bac4df9,
    name: "login"
  }, {
    path: "/maintenance",
    component: _75bd32a3,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _747e3a54,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _24203fa8,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _e9f8bf58,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _09a4b6f4,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _48980bd0,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _3c6e41f8,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _2568d1eb,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _d7ed6fa8,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _52d9c7de,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _bbf6aa3c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
